import StorageService from "./StorageService";
import { SESSION_USER } from "config/session";
import { authUser } from "requests";

class AuthenticationService {
  static login(obj) {
    const result = new Promise((resolve, reject) => {
      authUser(obj)
        .then((data) => {
          let dataToSave = {
            token: data.token,
            user: data.data._id,
            company: data.data.companies[0]._id,
          };
          StorageService.set(SESSION_USER, dataToSave);
          resolve(data.data);
        })
        .catch(() => {
          reject(new Error("Usuario y/o contraseña incorrectos."));
        });
    });
    return result;
  }

  static logout() {
    return new Promise((resolve) => {
      StorageService.remove(SESSION_USER);
      resolve();
    });
  }
}

export default AuthenticationService;
