import axios from "axios";
import { API_NAPCOBRANZAS } from "config/api.config";
import UserService from "config/UserService";
import { v4 as uuidv4 } from "uuid";

export const consultInfoPerson = async (t_doc, n_doc) => {
  const res = await axios.post(
    `${API_NAPCOBRANZAS}/contract/information`,
    { t_doc, n_doc },
    { headers: { Authorization: UserService.token() } }
  );

  return {
    _idext: uuidv4(),
    name: res.data.nombre_o_razon_social ?? res.data.nombre,
    ruc: res.data.ruc ?? res.data.dni,
    address: res.data.direccion ?? "",
    email: "",
    system: "NAPCOBRANZAS",
  };
};
