import axios from "axios";
import { API_NAPCOBRANZAS } from "config/api.config";
import UserService from "config/UserService";

export const postContracts = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/contract/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getContracts = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/contract/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putContracts = async (data, id) => {
  let res = await axios.put(`${API_NAPCOBRANZAS}/contract/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
