import axios from "axios";
import { API_NAPCOBRANZAS } from "config/api.config";
import UserService from "config/UserService";

export const getSerie = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/serie/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postSerie = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/serie/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putSerie = async (idSerie, data) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/serie/${idSerie}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
