import axios from "axios";
import { API_NAPCOLEGIOS, API_NAPCONTABLE, API_NAPFOOD, API_NAPNEGOCIOS } from "config/api.config";

export const getCompaniesNapContable = async () => {
  const res = await axios.get(`${API_NAPCONTABLE}/company`);
  return res.data;
};

export const getCompaniesNapFood = async () => {
  const res = await axios.get(`${API_NAPFOOD}/restaurant/enabled-public`);
  return res.data;
};

export const getCompaniesNapNegocios = async () => {
  const res = await axios.get(`${API_NAPNEGOCIOS}/company/public/connection`);
  return res.data;
};

export const getCompaniesNapColegios = async () => {
  const res = await axios.get(`${API_NAPCOLEGIOS}/company/public/connection`);
  return res.data;
};
