import axios from "axios";
import { API_NAPCOBRANZAS } from "config/api.config";
import UserService from "config/UserService";

export const getPayments = async (clientId) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/payment/${clientId}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putPayments = async (body, serieId) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/payment/pay/${serieId}`, body, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const assignDiscounts = async (body) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/payment/discounts`, body, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getLastPayment = async (clientId) => {
  const res = await axios.get(
    `${API_NAPCOBRANZAS}/payment/last-payment/${clientId}/${UserService.company()}`,
    { headers: { Authorization: UserService.token() } }
  );
  return res.data;
};

export const generateManualPayment = async (body, serieId) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/payment/manual/${serieId}`, body, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const generatePayments = async (data) => {
  let res = await axios.post(`${API_NAPCOBRANZAS}/payment/generate/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
