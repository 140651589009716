import axios from "axios";
import { API_NAPCOBRANZAS } from "config/api.config";
import UserService from "config/UserService";

export const getCompanies = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/company`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postCompanies = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/company`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putCompanies = async (data, id) => {
  let res = await axios.put(`${API_NAPCOBRANZAS}/company/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
