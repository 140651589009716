import axios from "axios";
import { API_NAPCOBRANZAS } from "config/api.config";
import UserService from "config/UserService";

export const getBillingCompany = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/fact/information/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postBillingCompany = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/fact/register/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// type = 01 || 03
export const getVouchers = async (type) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/payment/facturation-pay/${type}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getCreditNote = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/creditnote/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const generateCreditNote = async (operationId, data) => {
  let res = await axios.post(`${API_NAPCOBRANZAS}/creditnote/${operationId}/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const sendEmailVoucher = async (data) => {
  let res = await axios.post(`${API_NAPCOBRANZAS}/fact/send-email/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// typeVoucher => 01=factura; 03=boleta
export const getPendingVouchers = async (typeVoucher) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/payment/pending/${typeVoucher}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const sendSunat = async (idOperation) => {
  let res = await axios.post(
    `${API_NAPCOBRANZAS}/payment/sunat/${idOperation}/${UserService.company()}`,
    {},
    { headers: { Authorization: UserService.token() } }
  );
  return res.data;
};

export const sendMassiveBallots = async (ids) => {
  let res = await axios.post(`${API_NAPCOBRANZAS}/payment/massive-sunat/${UserService.company()}`, ids, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// OBTENER ENVÍOS MASIVOS

export const getMassiveComprobants = async () => {
  let res = await axios.get(`${API_NAPCOBRANZAS}/massive/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// REVISAR ESTADO DEL COMPROBANTE

export const checkStatusComprobants = async (id) => {
  let res = await axios.get(`${API_NAPCOBRANZAS}/massive/status-ticket/${id}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// VOLVER A ENVIAR EL MASIVO

export const resendMassiveToSunat = async (id) => {
  let res = await axios.get(`${API_NAPCOBRANZAS}/payment/resend-massive-sunat/${id}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// ANULAR BEFORE SEND TO SUNAT

export const anularBoleta = async (id) => {
  const res = await axios.put(
    `${API_NAPCOBRANZAS}/payment/anulated/${id}`,
    {},
    { headers: { Authorization: UserService.token() } }
  );
  return res.data;
};

export const anularManual = async (id) => {
  const res = await axios.put(
    `${API_NAPCOBRANZAS}/payment/anulated-manual/${id}`,
    {},
    { headers: { Authorization: UserService.token() } }
  );
  return res.data;
};

export const getVouchersById = async (id) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/payment/status/id/${id}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getCreditNoteById = async (id) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/creditnote/id/${id}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getPaymentsDebts = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/payment/debts/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
