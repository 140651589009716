import { lazy } from "react";
import Loadable from "./Loadable";
// ----------------------------------------------------------------------
const Page404 = Loadable(lazy(() => import("pages/Errors/Page404")));
// ----------------------------------------------------------------------
const DashboardLayout = Loadable(lazy(() => import("layouts/dashboard")));
const FreeLayout = Loadable(lazy(() => import("layouts/FreeLayout")));
// ----------------------------------------------------------------------
const Login = Loadable(lazy(() => import("pages/Login")));
const Home = Loadable(lazy(() => import("pages/Home")));
// ----------------------------------------------------------------------
const ForgotPassword = Loadable(lazy(() => import("pages/Errors/ForgotPwd")));
// ----------------------------------------------------------------------
const Dashboard = Loadable(lazy(() => import("pages/Dashboard")));
const Companies = Loadable(lazy(() => import("pages/Companies")));
const Users = Loadable(lazy(() => import("pages/Users")));
const Contracts = Loadable(lazy(() => import("pages/Contracts")));
const Services = Loadable(lazy(() => import("pages/Services")));
const Payments = Loadable(lazy(() => import("pages/Payments")));
const Pending = Loadable(lazy(() => import("pages/Sunat/Pending")));
const Comprobants = Loadable(lazy(() => import("pages/Sunat/Comprobants")));
const Manual = Loadable(lazy(() => import("pages/Sunat/Manual")));
const Generate = Loadable(lazy(() => import("pages/Sunat/Generate")));
const Historical = Loadable(lazy(() => import("pages/Sunat/Historical")));
const DetailsComprobant = Loadable(lazy(() => import("pages/Sunat/components/Details")));
const DetailsPDF = Loadable(lazy(() => import("pages/Sunat/components/PDF")));
const Discounts = Loadable(lazy(() => import("pages/Discounts")));
const Clients = Loadable(lazy(() => import("pages/Clients")));
const Reports = Loadable(lazy(() => import("pages/Reports")));
const ResumeReport = Loadable(lazy(() => import("pages/Reports/Resume")));
const VouchersReport = Loadable(lazy(() => import("pages/Reports/Vouchers")));
const AccountingReport = Loadable(lazy(() => import("pages/Reports/Accounting")));
const CreditNoteReport = Loadable(lazy(() => import("pages/Reports/CreditNote")));
const MethodPayments = Loadable(lazy(() => import("pages/Methods")));
const ListaCorte = Loadable(lazy(() => import("pages/ListaDeudas")));
const Messages = Loadable(lazy(() => import("pages/Messages")));
// ----------------------------------------------------------------------
const Profile = Loadable(lazy(() => import("pages/Profile")));

const routes = [
  {
    path: "/home",
    element: FreeLayout,
    roles: [],
    isPrivate: false,
    children: [
      {
        path: "",
        element: Home,
        roles: [],
      },
    ],
  },
  {
    path: "/",
    roles: ["superadmin", "admin"],
    element: DashboardLayout,
    isPrivate: true,
    children: [
      {
        path: "",
        element: Dashboard,
        roles: ["superadmin", "admin"],
      },
      {
        path: "perfil",
        element: Profile,
        roles: ["superadmin", "admin"],
      },
      // COMPONENTS
      {
        path: "companies",
        element: Companies,
        roles: ["superadmin"],
      },
      {
        path: "usuarios",
        element: Users,
        roles: ["superadmin"],
      },
      {
        path: "clients",
        element: Clients,
        roles: ["superadmin", "admin"],
      },
      {
        path: "contratos",
        element: Contracts,
        roles: ["superadmin", "admin"],
      },
      {
        path: "servicios",
        element: Services,
        roles: ["superadmin", "admin"],
      },
      {
        path: "pagos",
        element: Payments,
        roles: ["superadmin", "admin"],
      },
      {
        path: "sunat/pendientes",
        element: Pending,
        roles: ["superadmin", "admin"],
      },
      {
        path: "sunat/emitidos",
        element: Comprobants,
        roles: ["superadmin", "admin"],
      },
      {
        path: "sunat/generar",
        element: Manual,
        roles: ["superadmin", "admin"],
      },
      {
        path: "sunat/historico",
        element: Historical,
        roles: ["superadmin", "admin"],
      },
      {
        path: "sunat/registrar",
        element: Generate,
        roles: ["superadmin"],
      },
      {
        path: "sunat/detail-voucher/:idVoucher/:type/:from",
        element: DetailsComprobant,
        roles: ["superadmin", "admin"],
      },
      {
        path: "sunat/detail-pdf/:idVoucher/:type",
        element: DetailsPDF,
        roles: ["superadmin", "admin"],
      },
      {
        path: "descuentos",
        element: Discounts,
        roles: ["superadmin", "admin"],
      },
      {
        path: "lista-deudas",
        element: ListaCorte,
        roles: ["superadmin", "admin"],
      },
      {
        path: "reportes",
        element: Reports,
        roles: ["superadmin", "admin"],
      },
      {
        path: "reportes/historial",
        element: ResumeReport,
        roles: ["superadmin", "admin"],
      },
      {
        path: "reportes/:type",
        element: VouchersReport,
        roles: ["superadmin", "admin"],
      },
      {
        path: "reportes/contabilidad",
        element: AccountingReport,
        roles: ["superadmin", "admin"],
      },
      {
        path: "reportes/notas_de_credito",
        element: CreditNoteReport,
        roles: ["superadmin", "admin"],
      },
      { path: "metodos/pago", element: MethodPayments, roles: ["superadmin", "admin"] },
      {
        path: "mensajes",
        element: Messages,
        roles: ["superadmin", "admin"],
      },
      // IF VIEW DOESN'T EXIST
      {
        path: "*",
        element: Page404,
        roles: ["superadmin", "admin"],
      },
    ],
  },
  {
    path: "/login",
    element: Login,
    isPrivate: false,
    roles: [],
  },
  {
    path: "/forgot-password",
    element: ForgotPassword,
    isPrivate: false,
    roles: [],
  },
  {
    path: "*",
    element: Page404,
    isPrivate: false,
    roles: [],
  },
];

export default routes;
