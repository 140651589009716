import axios from "axios";
import { API_NAPCOBRANZAS } from "config/api.config";
import UserService from "config/UserService";

export const getReportAnualData = async (year) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/report/anual/${UserService.company()}/${year}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getVoucherReportData = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/report/vouchers/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getAccountingReportData = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/report/accounting/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getCreditNoteReportData = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/report/creditnote/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
