import { createContext, useContext, useLayoutEffect, useState } from "react";
import { notification } from "antd";
import {
  getCompaniesNapFood,
  getCompaniesNapContable,
  getCompaniesNapNegocios,
  getCompaniesNapColegios,
} from "requests/external";
import { getCompaniesNapCobranzas } from "requests/client";
import sleep from "utils/sleep";
import UserService from "config/UserService";

export const ClientContext = createContext();
export const useClientContext = () => useContext(ClientContext);

const ClientContextProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [load, setLoading] = useState(true);

  useLayoutEffect(() => {
    if (UserService.token()) obtainClients();
  }, []);

  const obtainClients = async () => {
    try {
      setLoading(true);
      const [res1, res2, res3, res4, res5] = await Promise.all([
        getCompaniesNapContable(),
        getCompaniesNapFood(),
        getCompaniesNapCobranzas(),
        getCompaniesNapColegios(),
        getCompaniesNapNegocios(),
      ]);

      const result = [
        ...formatData(res5, "NAPNEGOCIOS"),
        ...formatData(res4, "NAPCOLEGIOS"),
        ...formatData(res3, "NAPCOBRANZAS"),
        ...formatData(res2, "NAPFOOD", true),
        ...formatData(res1.data, "NAPCONTABLE"),
      ];
      setClients([...result]);
    } catch (error) {
      notification["error"]({
        message: `Ocurrió un error al realizar la operación.`,
        description: "Volviendo a intentar en 60 segundos...",
      });
      await sleep(60000);
      await obtainClients();
    } finally {
      setLoading(false);
    }
  };

  return <ClientContext.Provider value={{ load, clients, obtainClients }}>{children}</ClientContext.Provider>;
};

export default ClientContextProvider;

const formatData = (data, system, omitActive) => {
  const format = [];

  data
    .filter((item) => (omitActive ? true : item.active)) // item.ruc.toString().length === 11 &&
    .map((item) => ({
      logo: item.logo ?? "",
      ruc: item.ruc ?? "",
      name: item.tradeName ?? item.name ?? "",
      sedes: item.sedes ?? data.filter((sd) => sd.ruc === item.ruc).map((sd) => sd.sede ?? sd.tradeName ?? sd.name),
      system,
      phone: item.phone ?? "",
      email: item.email ?? "",
      address: item.address ?? "",
      createdAt: item.createdAt ?? "",
      _id: item._id ?? "",
    }))
    .forEach((item) => !format.find((i) => i.ruc === item.ruc) && format.push(item));

  return format;
};
